"use client";

import Image from "next/image";
import Link from "next/link";
import type { RenderLink, RenderImage } from "@/components/devlink";

import "./renderers.css"

export const LinkRenderer: RenderLink = ({
  href,
  className,
  children,
  ...props
}) => (
  <Link href={href} className={className} {...props}>
    {children}
  </Link>
);


const unoptimized = [
  'gallery_image',
  'fit-cover',
  'contact_image',
  'featured_slide-image',
]

const sizes = {
  'fit-cover': '(max-width: 767px) 100vw, (max-width: 1439px) 554.9921875px, (max-width: 1919px) 39vw, 33vw',
  'featured_slide-image': '(max-width: 479px) 100vw, (max-width: 767px) 99vw, (max-width: 991px) 66vw, 33vw',
  'header69_background-image': '(max-width: 767px) 100vw, (max-width: 1279px) 691.6875px, 54vw',
  'image-16': '(max-width: 767px) 100vw, (max-width: 991px) 534px, (max-width: 1279px) 208.03125px, (max-width: 1439px) 225.7890625px, (max-width: 1919px) 16vw, 13vw',
  'gallery_image': '(max-width: 479px) 50vw, 25vw',
  'contact_image': '(max-width: 991px) 100vw, 50vw',
  'explore_card-image': '(max-width: 479px) 100vw, 33vw',
} as any

export const ImageRenderer: RenderImage = ({
  src,
  alt,
  height,
  width,
  loading,
  className = 'UNDEFINED_CLASSNAME',
  fetchPriority,
  ...props
}) => {
  const priority = fetchPriority === 'high'
  const imgProps = {
    priority,
    loading: priority ? 'eager' : loading,
    className,
    src: src || "",
    alt: alt || "",
    unoptimized: unoptimized.indexOf(className) > -1 || src?.match(/.svg$/),
    sizes: sizes[className],
    ...props,
  } as any;

  // Note: this will fill the image to its parent element container
  // so you'll need to style the parent container with the desired size.
  if (width === 'auto' || width === 'Auto' || height === 'auto' || height === 'Auto') {
    imgProps.fill = true

  } else {
    imgProps.width = width === "auto" ? undefined : (width as number)
    imgProps.height = height === "auto" ? undefined : (height as number)
  }

  return <Image {...imgProps} />;
};
