'use client'
import ReactDOM from 'react-dom'

export function PreloadResources() {
  ReactDOM.preconnect('https://www.googletagmanager.com')
  ReactDOM.preconnect('https://www.google-analytics.com')
  ReactDOM.preconnect('https://lineups.imgix.net')
  ReactDOM.preconnect('https://cdn.filestackcontent.com')
  ReactDOM.preconnect('https://cdn.lineups.io')
  ReactDOM.preconnect('https://res.cloudinary.com')
  ReactDOM.preconnect(`https://${ process.env.NEXT_PUBLIC_ALGOLIA_APP_ID }-dsn.algolia.net`)
  return null
}
